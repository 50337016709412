
import { createVNode, defineComponent, ref, onMounted } from 'vue'
import { ExclamationCircleOutlined, EditOutlined, DeleteOutlined, FilterOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons-vue'
import { notification, Modal } from 'ant-design-vue'

import courseService from '../../../services/course'
import categoryService from '../../../services/category'
import commonServices from '../../../services/common'

import ICourse from '../../../interface/course'

import router from '../../../router'

export default defineComponent({
  props: ['courseType', 'courseId'],
  components: {
    DeleteOutlined,
    FilterOutlined,
    EditOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined
  },
  setup (props, { emit }) {
    const columns = [
      {
        title: 'Title',
        onFilter: (value, record) => record.title.indexOf(value) === 0,
        sorter: (a, b) => a.title.localeCompare(b.title),
        sortDirections: ['descend', 'ascend'],
        slots: { customRender: 'titleSection' }
      },
      {
        title: 'Category',
        dataIndex: 'category.title',
        onFilter: (value, record) => record.category.indexOf(value) === 0,
        sorter: (a, b) => a.category.title.localeCompare(b.category.title),
        sortDirections: ['descend', 'ascend']
      },
      {
        title: 'Type',
        dataIndex: 'type'
      },
      {
        title: 'Status',
        dataIndex: 'published',
        onFilter: (value, record) => record.published.indexOf(value) === 0,
        sorter: (a, b) => a.published - b.published,
        sortDirections: ['descend', 'ascend'],
        slots: { customRender: 'published' }
      },
      {
        title: 'Price',
        dataIndex: 'price',
        onFilter: (value, record) => record.price.indexOf(value) === 0,
        sorter: (a, b) => a.price - b.price,
        sortDirections: ['descend', 'ascend'],
        slots: { customRender: 'price' }
      },
      {
        title: 'Action',
        key: 'action',
        slots: { customRender: 'action' }
      }
    ]
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const courses = ref<Array<ICourse>>([])
    let category = null
    let status = null
    const getAllParentCourse = async () => {
      try {
        const responce = await courseService.getParentCourses(category, status)
        courses.value = responce.data
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const categories = ref<Array<{ _id: string; title: string; description: string; image: string; parent_id: string }>>([])
    const filterByStatus = async (value) => {
      status = value
      getAllParentCourse()
      // try {
      //   const responce = await courseService.getCourseByStatus(value)
      //   courses.value = responce.data
      // } catch (error) {
      //   notify('Error', error.data, 'error')
      // }
    }
    const filterByCategory = async (value) => {
      category = value
      getAllParentCourse()
      // try {
      //   const responce = await courseService.getCourseByCategory(value)
      //   courses.value = responce.data
      // } catch (error) {
      //   notify('Error', error.data, 'error')
      // }
    }
    const filterOption = (input, option) => {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    }
    const getCategories = async () => {
      try {
        const res = await categoryService.getCategory()
        categories.value = res.data
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const getAllChildCourse = async (parentId) => { // Need to implement
      try {
        const profile = commonServices.getCurrentProfile()
        const responce = await courseService.getChildCourses(parentId, profile._id, 'All')
        courses.value = responce.data
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const viewCourse = async (course) => {
      if (course.type === 'Single') {
        // Show Chapter
        router.push(`/course/${course._id}/chapter`)
      } else {
        // Show sub course
        router.push(`/course/${course._id}/subcourse`)
      }
    }
    const editCourse = async (course) => {
      router.push(`/course/edit/${course._id}`)
    }
    const courseDelete = async (course, index) => {
      try {
        await courseService.deleteCourse(course._id)
        courses.value.splice(index, 1)
        notify('Success', `${course.title} deleted`, 'success')
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const courseStatus = async (course, status) => {
      try {
        const id = course._id
        course.published = status
        course.category = course.category._id
        await courseService.updateCourse(course, id)
        emit('refreshCourseStatistics')
        notify('Success', (status ? 'Course published' : 'Course Unpublished'), 'success')
        if (props.courseType === 'Parent') {
          getAllParentCourse()
        } else {
          getAllChildCourse(props.courseId)
        }
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const showDeleteConfirm = async (course, index) => {
      Modal.confirm({
        title: 'Are you sure?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode('div', { style: 'color:red;' }, 'Do you really want to delete this record?. This process cannot be undo.'),
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        async onOk () {
          await courseDelete(course, index)
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    onMounted(() => {
      getCategories()
      if (props.courseType === 'Parent') {
        getAllParentCourse()
      } else {
        getAllChildCourse(props.courseId)
      }
    })
    return {
      columns,
      filterByStatus,
      filterByCategory,
      filterOption,
      getAllParentCourse,
      getAllChildCourse,
      courseStatus,
      courses,
      categories,
      getCategories,
      showDeleteConfirm,
      viewCourse,
      editCourse,
      courseDelete,
      notify,
      category,
      status
    }
  }
})
