import axios from 'axios'

export default {
  getCourses () {
    return axios.get(process.env.VUE_APP_USER_COURSE)
  },

  getParentCourses (category?, status?) {
    return axios.get(process.env.VUE_APP_USER_COURSE + '/parent/getAll?category=' + category + '&status=' + status)
  },

  getCourseByCategory (id) {
    return axios.get(process.env.VUE_APP_USER_COURSE + '/course_by_category/' + id)
  },

  getCourseByStatus (status) {
    return axios.get(process.env.VUE_APP_USER_COURSE + '/course_by_status/' + status)
  },

  getChildCourses (id, profileId, status?) {
    return axios.get(process.env.VUE_APP_USER_COURSE + '/child/' + id + '/' + profileId + '?status=' + status)
  },

  getOpenCourses (clientId) {
    return axios.get(process.env.VUE_APP_USER_COURSE + '/open-course/' + clientId)
  },

  getCompletedCourses (profileId) {
    return axios.get(process.env.VUE_APP_USER_COURSE + '/completed-courses/' + profileId)
  },

  getClientCoursesByType (clientId, type) {
    return axios.get(process.env.VUE_APP_USER_COURSE + '/by_type/' + clientId + '/' + type)
  },

  getStatistics () {
    return axios.get(process.env.VUE_APP_USER_COURSE + '/statistics')
  },

  createCourse (data) {
    return axios.post(process.env.VUE_APP_USER_COURSE, data)
  },

  updateCourse (data, id) {
    return axios.put(process.env.VUE_APP_USER_COURSE + '/' + id, data)
  },

  getCourseDetails (id) {
    return axios.get(process.env.VUE_APP_USER_COURSE + '/' + id)
  },

  deleteCourse (id) {
    return axios.delete(process.env.VUE_APP_USER_COURSE + '/' + id)
  },

  addCourseOutcome (data) {
    return axios.post(process.env.VUE_APP_USER_COURSE_OUTCOME, data)
  },

  updateCourseOutcome (data, id) {
    return axios.put(process.env.VUE_APP_USER_COURSE_OUTCOME + '/' + id, data)
  },

  getCourseOutcome (id) {
    return axios.get(process.env.VUE_APP_USER_COURSE_OUTCOME + '/course/' + id)
  },

  deleteOutcome (id) {
    return axios.delete(process.env.VUE_APP_USER_COURSE_OUTCOME + '/' + id)
  },

  enrollMultipleCourse (data) {
    return axios.post(process.env.VUE_APP_COURSE_ENROLL + '/multiple', data)
  },

  getAssignedCourse (id) {
    return axios.get(process.env.VUE_APP_COURSE_ENROLL + '/course/assigned/' + id)
  },

  getMyCourse (id) {
    return axios.get(process.env.VUE_APP_COURSE_ENROLL + '/course/my-course/' + id)
  },

  getGroupCourse (id) {
    return axios.get(process.env.VUE_APP_GROUP + '/client/group_course/' + id)
  },

  getUnAssignedCourse (id) {
    return axios.get(process.env.VUE_APP_COURSE_ENROLL + '/course/un-assigned/' + id)
  },

  unAssignCourse (id) {
    return axios.delete(process.env.VUE_APP_COURSE_ENROLL + '/' + id)
  },

  getCandidateUnAssignedCourse (clientId, profileId) {
    return axios.get(process.env.VUE_APP_COURSE_ENROLL + '/candidate/course/un-assigned/' + clientId + '/' + profileId)
  },

  reTakeCourse (id, profile) {
    return axios.post(process.env.VUE_APP_USER_COURSE + '/re-attempt/' + id + '/' + profile)
  }
}
