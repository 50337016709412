import axios from 'axios'

export default {
  getCategory () {
    return axios.get(process.env.VUE_APP_USER_CATEGORY + '/parent/all')
  },

  storeCategory (data) {
    return axios.post(process.env.VUE_APP_USER_CATEGORY, data)
  },

  updateCategory (data, id) {
    return axios.put(process.env.VUE_APP_USER_CATEGORY + '/' + id, data)
  },

  getCategoryDetails (id) {
    return axios.get(process.env.VUE_APP_USER_CATEGORY + '/' + id)
  },

  deleteCategory (id) {
    return axios.delete(process.env.VUE_APP_USER_CATEGORY + '/' + id)
  }
}
